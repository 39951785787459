<template>
  <div id="order">
    <div class="admin_main_block">
      <div class="user_temp_title">
        订单列表
      </div>
      <div class="admin_main_block_top clearfix">
        <div class="admin_main_block_left">
          <div style="width: 170px">
            <el-input size="small" placeholder="请输入订单号" v-model="searchData.orderId" clearable></el-input>
          </div>
          <div style="width: 400px">
            <el-date-picker v-model="time" type="datetimerange" size="small" clearable range-separator="至"
              start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
          </div>
          <div style="width: 160px">
            <el-select size="small" v-model="searchData.status" clearable>
              <el-option v-for="item in statusList" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </div>
          <div>
            <el-button size="small" type="primary" icon="el-icon-search" @click="search">查询</el-button>
          </div>
        </div>
      </div>
      <div class="admin_table_main">
        <el-table size="small" :data="list" v-loading="loading">
          <el-table-column label="订单号" prop="orderId"> </el-table-column>
          <el-table-column label="订单状态">
            <template slot-scope="scope">
              <span>{{ scope.row.statusText }}</span>
            </template>
          </el-table-column>
          <el-table-column label="导入方式">
            <template slot-scope="scope">
              <span v-if="scope.row.importType == 1">{{ getOrderType(scope.row.belongTerrace) }}手动输入</span>
              <span v-if="scope.row.importType == 2">{{ getOrderType(scope.row.belongTerrace) }}模板导入</span>
              <span v-if="scope.row.importType == 3">{{ getOrderType(scope.row.belongTerrace) }}智能筛选</span>
              <span v-if="scope.row.importType == 5">{{ getOrderType(scope.row.belongTerrace) }}一键下单</span>
            </template>
          </el-table-column>
          <el-table-column label="支付时间" prop="payTime">
            <template slot-scope="scope">
              {{ (scope.row.payTime / 1000) | date("Y-m-d H:i:s") }}
            </template>
          </el-table-column>
          <el-table-column label="包裹数" prop="pkCount"> </el-table-column>
          <el-table-column label="总费用" prop="amount"> </el-table-column>

          <el-table-column label="财务备注" prop="remark" show-overflow-tooltip>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button size="mini" type="success" v-if="scope.row.status != -1" @click="
                $router.push('/member/receipt?orderId=' + scope.row.orderId)
                ">查看详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="home_fy_block">
          <el-pagination v-if="total > 0" background @current-change="handleCurrentChange"
            :current-page="searchData.pageNo" :page-size="searchData.pageSize" layout="total, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog title="发货失败" :visible.sync="dialogVisible" width="30%">
      <span>{{ msg }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      time: [],
      state: 1,
      statusList: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "出单中",
          value: 2,
        },
        {
          label: "发货中",
          value: 3,
        },
        {
          label: "交易成功",
          value: 4,
        },
        {
          label: "撤销订单",
          value: -1,
        },
        {
          label: "已退款",
          value: 5,
        },
      ],
      searchData: {
        pageNo: 1,
        pageSize: 10,
        status: "",
        id: "",
        busId: "",
        startTime: "",
        endTime: "",
        orderId: "",
      },
      list: [],
      total: 0,
      loading: false, //防重复点击
      dialogVisible: false,
      msg: "",
      oldTypeList: [
        { value: 1, label: "新数据" },
        { value: 2, label: "旧数据" },
      ],
    };
  },
  created() {
    var status = this.$route.query.status;
    if (status) {
      this.searchData.status = Number(status);
    }

    this.getNewList();

  },
  mounted() { },
  watch: {},
  methods: {
    getOrderType(belongTerrace) {
      if (belongTerrace == '2') {
        return "淘宝/天猫"
      } else if (belongTerrace == '3') {
        return "京东"
      } else if (belongTerrace == '4') {
        return "拼多多"
      } else if (belongTerrace == '5') {
        return "抖音"
      } else if (belongTerrace == '6') {
        return "快手"
      } else if (belongTerrace == '1') {
        return "其他"
      }
    },
    showKeyDelivery(row) {
      if (
        row.shopId &&
        (row.status == 2 ||
          (row.status == 1 && row.payTime + 120000 < new Date()))
      ) {
        return true;
      }
      return false;
    },
    showBathDelivery(row) {
      if (
        (row.status == 2 ||
          row.status == 3 ||
          (row.status == 1 && row.payTime + 120000 < new Date())) &&
        (row.belongTerrace == 2 || row.belongTerrace == 3) &&
        row.importType == 1
      ) {
        return true;
      }
      return false;
    },
    aKeyDelivery(id) {
      this.loading = true;
      this.$request.post({
        url: "/bus/order/aKeyDelivery",
        params: {
          id,
        },
        success: (res) => {
          if (res.length > 0) {
            let str = [];
            res.forEach((res) => {
              str.push(res.orderNo);
            });
            str = str.join(",");
            str = `以下订单发货失败:${str}`;
            this.msg = str;
            this.dialogVisible = true;
          } else {
            this.$message.success("操作成功");

            this.getNewList();
          }
        },
        finally: (res) => {
          this.loading = false;
        },
      });
    },
    search() {
      this.searchData.pageNo = 1;
      this.getNewList();
    },
    getNewList() {
      if (this.time && this.time.length > 0) {
        this.searchData.startTime = this.time[0].getTime();
        this.searchData.endTime = this.time[1].getTime();
      } else {
        this.searchData.startTime = "";
        this.searchData.endTime = "";
      }
      this.loading = true;
      this.$request.post({
        url: "/ReportApi/business/order/getOrderInfo",
        params: this.searchData,
        success: (result) => {
          const tar = result.data.map(item => {
            const tarItem = this.statusList.find(vo => vo.value == item.status)
            item.statusText = tarItem.label
            return item
          })

          this.list = tar;
          this.total = result.total;
        },
        finally: () => {
          this.loading = false;
        },
      });
    },
    handleCurrentChange(val) {
      this.searchData.pageNo = val;
      this.getNewList();
    },

    revocation(id) {
      if (this.loading) return;
      this.loading = true;

      this.$request.post({
        url: "/ReportApi/business/cancel",
        params: { orderId: id },
        success: (res) => {
          this.$message.success("撤销成功");

          this.getNewList();
        },
        finally: () => {


        },
      });
    },

    payOrder(id) {
      if (this.loading) return;
      this.loading = true;
      this.$request.post({
        url: "/ReportApi/business/pay",
        params: { orderId: id },
        success: (res) => {
          this.$message.success("支付成功");

          this.getNewList();
        },
        finally: () => {
          this.loading = false;
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.admin_table_main .el-button {
  margin-left: auto;
  margin-top: 2px;
}

#order {
  .admin_main_block {
    background: #fff;
    width: 100%;
    box-sizing: border-box;
    min-height: 855px;
    padding: 20px;
    border-radius: 5px;

    .user_temp_title {
      margin: auto;
      padding-left: 20px;
      border-bottom: 1px solid #eeeeee;
      padding-bottom: 20px;
      height: 25px;
      margin-bottom: 20px;
      font-weight: bold;
    }

    .admin_main_block_top {
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid #efefef;

      .admin_main_block_left {
        float: left;

        div {
          float: left;
          margin-right: 15px;
        }
      }
    }

    .home_fy_block {
      margin-top: 30px;
      text-align: right;
      margin-right: 10px;
    }
  }
}
</style>
